<template>
  <Portlet
    title="Screenshot"
    icon="tablet-alt"
  >
    <div class="container">
      <div class="row">
        <div
          v-for="(item, index) in screenshotData"
          :key="`ss-${ index }`"
          class="col-xl-4 col-12 col-lg-6 mb-3"
          style="border-bottom: 1px solid; padding-bottom:10px; min-height: 300px "
        >
          <router-link
            :to="`/installation/${ item.installationId }/devices/device/${item.uuid}`"
            class="alt-primary-color"
            target="_blank"
          >
            <span><b>{{ item.name }}</b></span>
          </router-link>
          <DeviceDetailScreenshot
            ref="deviceDetailScreenshot"
            :uuid="item.uuid"
            :auto-refresh="false"
          />
        </div>
      </div>
    </div>
  </Portlet>
</template>

<script>
export default {
  name: "TerminalScreenshotView",
  components: {
    DeviceDetailScreenshot: () => import('@/components/Device/DeviceDetail/DeviceDetailScreenshot.vue')
  },
  data () {
    return {
      screenshotData: []
    }
  },
  created () {
    this.screenshotData = JSON.parse(localStorage.getItem('terminalScreenshotData'));
  },
  metaInfo () {
    return {
      title: 'Screenshots'
    }
  }
}
</script>